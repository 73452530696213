import gql from 'graphql-tag';

export const API_TRANSACTIONS_WITH_ALL_SUBSCRIPTION = gql`
subscription ApiTransactionsWithAllSubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: [bigint!]!){
	view_facility_revenue_aggregate(where: {created_at: {_gte: $start, _lte: $end}, facility_id: {_in: $facility_id},})  {
		aggregate {
		  count
		}
	  }
	}
`;
export const API_TRANSACTIONS_WITH_ALL_AND_POWER_TYPE_SUBSCRIPTION = gql`
subscription ApiTransactionsWithAllSubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: [bigint!]!, $power_type: String!){
	view_facility_revenue_aggregate(where: {created_at: {_gte: $start, _lte: $end}, facility_id: {_in: $facility_id},power_type: {_eq: $power_type}})  {
		aggregate {
		  count
		}
	  }
	}
`;
export const API_TRANSACTIONS_SUBSCRIPTION = gql`
subscription ApiTransactionsSubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: bigint!){
	view_facility_revenue_aggregate(where: {created_at: {_gte: $start, _lte: $end}, facility_id: {_eq: $facility_id}})  {
		aggregate {
		  count
		}
	  }
	}
`;
export const API_TRANSACTIONS_AND_POWER_TYPE_SUBSCRIPTION = gql`
subscription ApiTransactionsSubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: bigint!, $power_type: String!){
	view_facility_revenue_aggregate(where: {created_at: {_gte: $start, _lte: $end}, facility_id: {_eq: $facility_id},power_type: {_eq: $power_type}})  {
		aggregate {
		  count
		}
	  }
	}
`;
export const API_COMPLETED_BOOKING_SUBSCRIPTION = gql`
subscription ApiCompletedBookingSubscription($start: timestamptz! ,$end: timestamptz!,$facility_id: bigint!){
	view_facility_revenue_aggregate(where: {status: {_eq: "Completed"} , created_at: {_gte: $start , _lte: $end}, facility_id: {_eq: $facility_id}}) {
    aggregate {
      count
    }
  }
}
`;
export const API_COMPLETED_BOOKING_WITH_ALL_SUBSCRIPTION = gql`
subscription ApiCompletedbookingSubscription($start: timestamptz! ,$end: timestamptz!,  $facility_id: [bigint!]!){
	view_facility_revenue_aggregate(where: {status: {_eq: "Completed"} , created_at: {_gte: $start , _lte: $end},  facility_id: {_in: $facility_id}}) {
    aggregate {
      count
    }
  }
}
`;
export const API_TOTAL_REVENUE_FACILITY_SUBSCRIPTION = gql`
subscription ApiTotalRevenueFacilitySubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: bigint!){
	view_facility_revenue(where: {created_at: {_gte: $start , _lte: $end}, facility_id:{_eq: $facility_id}}) {
		total_amount_spent
		meter_start_at
		meter_stop_at
		units_consumed
		power_type
		is_fleet
		facility_id
	  }
	}
`;
export const API_TOTAL_REVENUE_FACILITY_AND_POWER_TYPE_SUBSCRIPTION = gql`
subscription ApiTotalRevenueFacilitySubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: bigint!, $power_type: String!){
	view_facility_revenue(where: {created_at: {_gte: $start , _lte: $end}, facility_id:{_eq: $facility_id}, power_type: {_eq: $power_type}}) {
		total_amount_spent
		meter_start_at
		meter_stop_at
		units_consumed
		power_type
		is_fleet
		facility_id
	  }
	}
`;
export const API_TOTAL_REVENUE_WITH_ALL_FACILITY_SUBSCRIPTION = gql`
subscription ApiTotalRevenueWithAllFacilitySubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: [bigint!]!){
	view_facility_revenue(where: {created_at: {_gte: $start , _lte: $end} , facility_id: {_in: $facility_id}}) {
		total_amount_spent
		meter_start_at
		meter_stop_at
		units_consumed
		power_type
		is_fleet
		facility_id
	}
}
`;
export const API_TOTAL_REVENUE_WITH_ALL_FACILITY_AND_POWER_TYPE_SUBSCRIPTION = gql`
subscription ApiTotalRevenueWithAllFacilitySubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: [bigint!]!, $power_type: String!){
	view_facility_revenue(where: {created_at: {_gte: $start , _lte: $end} , facility_id: {_in: $facility_id}, power_type: {_eq: $power_type}}) {
		total_amount_spent
		meter_start_at
		meter_stop_at
		units_consumed
		power_type
		is_fleet
		facility_id
	}
}
`;

export const API_USERS_SUBSCRIPTION = gql`
subscription ApiUsersSubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: [bigint!]!){
	view_facility_revenue_aggregate(where: {created_at: {_gte: $start, _lt: $end}, facility_id: {_in: $facility_id}}) {
		aggregate {
			count(columns: user_id, distinct: true)
		  }
	  }
}
`;
export const API_USERS_AND_POWER_TYPE_SUBSCRIPTION = gql`
subscription ApiUsersSubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: [bigint!]!, $power_type: String!){
	view_facility_revenue_aggregate(where: {created_at: {_gte: $start, _lt: $end}, facility_id: {_in: $facility_id}, power_type: {_eq: $power_type}}) {
		aggregate {
			count(columns: user_id, distinct: true)
		  }
	  }
}
`;
export const API_USERS_WITH_FACILITY_SUBSCRIPTION = gql`
subscription ApiUsersSubscription($start: timestamptz! ,$end: timestamptz!,$facility_id: bigint!){
	view_facility_revenue_aggregate(where: {created_at: {_gte: $start, _lt: $end}, facility_id: {_eq: $facility_id}}) {
		aggregate {
			count(columns: user_id, distinct: true)
		  }
	  }
}
`;
export const API_USERS_WITH_FACILITY_AND_POWER_TYPE_SUBSCRIPTION = gql`
subscription ApiUsersSubscription($start: timestamptz! ,$end: timestamptz!,$facility_id: bigint!, $power_type: String!){
	view_facility_revenue_aggregate(where: {created_at: {_gte: $start, _lt: $end}, facility_id: {_eq: $facility_id}, power_type: {_eq: $power_type}}) {
		aggregate {
			count(columns: user_id, distinct: true)
		  }
	  }
}
`;
export const API_TOTAL_USERS_SUBSCRIPTION = gql`
subscription ApiTotalUsersSubscription($facility_id: [bigint!]!){
	view_facility_revenue_aggregate(where: {facility_id: {_in: $facility_id}}) {
		aggregate {
		  count(columns: user_id, distinct: true)
		}
	  }
}
`;
export const API_TOTAL_USERS_FACILITY_SUBSCRIPTION = gql`
subscription ApiTotalUsersSubscription($facility_id: bigint!){
	view_facility_revenue_aggregate(where: {facility_id: {_eq: $facility_id}}) {
		aggregate {
		  count(columns: user_id, distinct: true)
		}
	  }
}
`;
export const API_CONDITION_BASED_USERS_SUBSCRIPTION = gql`
subscription ApiConditionBasedUsersSubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: [bigint!]!){
	view_facility_revenue_aggregate(where: {created_at: {_gte: $start, _lte: $end}, facility_id: {_in: $facility_id}}) {
		aggregate {
		  count(columns: user_id, distinct: true)
		}
	  }
}
`;
export const API_CONDITION_BASED_USERS_FACILITY_SUBSCRIPTION = gql`
subscription ApiConditionBasedUsersFacilitySubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: bigint!){
	view_facility_revenue_aggregate(where: {created_at: {_gte: $start, _lte: $end}, facility_id: {_eq: $facility_id}}) {
		aggregate {
		  count(columns: user_id, distinct: true)
		}
	  }
}
`;


export const API_UNITS_CONSUMED_SUBSCRIPTION = gql`
subscription ApiUnitsConsumedSubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: bigint!){
	view_facility_revenue_aggregate(where: {created_at: {_gte: $start, _lt: $end}  , facility_id: {_eq: $facility_id}}) {
		aggregate {
		  sum {
			hours_consumed
			units_consumed
			session_by_time
			session_by_unit
			session_by_price
		  }
		}
	  }
	}
`;
export const API_UNITS_CONSUMED_AND_POWER_TYPE_SUBSCRIPTION = gql`
subscription ApiUnitsConsumedSubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: bigint!, $power_type: String!){
	view_facility_revenue_aggregate(where: {created_at: {_gte: $start, _lt: $end}  , facility_id: {_eq: $facility_id}, power_type: {_eq: $power_type}}) {
		aggregate {
		  sum {
			hours_consumed
			units_consumed
			session_by_time
			session_by_unit
			session_by_price
		  }
		}
	  }
	}
`;
export const API_UNITS_CONSUMED_ALL_SUBSCRIPTION = gql`
subscription ApiUnitsConsumedWithAllFacilitySubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: [bigint!]!){
	view_facility_revenue_aggregate(where: {created_at: {_gte: $start, _lt: $end} , facility_id: {_in: $facility_id} }){
		nodes {
			facility_name
			hours_consumed
			units_consumed
			facility_id
    		}
  		}
	}
`;
export const API_UNITS_CONSUMED_ALL_AND_POWER_TYPE_SUBSCRIPTION = gql`
subscription ApiUnitsConsumedWithAllFacilitySubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: [bigint!]!, $power_type: String!){
	view_facility_revenue_aggregate(where: {created_at: {_gte: $start, _lt: $end} , facility_id: {_in: $facility_id} , power_type: {_eq: $power_type} }){
		nodes {
			facility_name
			hours_consumed
			units_consumed
			facility_id
    		}
  		}
	}
`;
export const API_UNITS_CONSUMED_WITH_ALL_FACILITY_SUBSCRIPTION = gql`
subscription ApiUnitsConsumedWithAllFacilitySubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: [bigint!]!){
	view_facility_revenue_aggregate(where: {created_at: {_gte: $start, _lt: $end} , facility_id: {_in: $facility_id} }){
		aggregate {
		  sum {
      hours_consumed
			units_consumed
			session_by_time
			session_by_unit
			session_by_price
		  }
		}
	  }
	}
`;
export const API_UNITS_CONSUMED_WITH_ALL_FACILITY_AND_POWER_TYPE_SUBSCRIPTION = gql`
subscription ApiUnitsConsumedWithAllFacilitySubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: [bigint!]!, $power_type: String!){
	view_facility_revenue_aggregate(where: {created_at: {_gte: $start, _lt: $end} , facility_id: {_in: $facility_id}, power_type: {_eq: $power_type} }){
		aggregate {
		  sum {
      hours_consumed
			units_consumed
			session_by_time
			session_by_unit
			session_by_price
		  }
		}
	  }
	}
`;

export const API_PORT_STATUS = gql`
subscription ApiPortStatus($port_status: String!,$facility_id: [bigint!]!){
	view_count_port_status_aggregate(where: {facility_id: {_in: $facility_id}, port_status: {_eq: $port_status},charger_status: {_eq: true}}){
		aggregate {
		  sum {
			count
		  }
		}
		nodes {
			charger_name
			connector_id
			facility_name
			port_status
			updated_at
		  }
	  }
}
`;
export const API_PORT_STATUS_AND_POWER_TYPE = gql`
subscription ApiPortStatus($port_status: String!,$facility_id: [bigint!]!, $power_type:String!){
	view_count_port_status_aggregate(where: {facility_id: {_in: $facility_id}, port_status: {_eq: $port_status},charger_status: {_eq: true}, power_type: {_eq: $power_type}}){
		aggregate {
		  sum {
			count
		  }
		}
		nodes {
			charger_name
			connector_id
			facility_name
			port_status
			updated_at
		  }
	  }
}
`;

export const API_PORT_STATUS_FOR_CHARGING_AND_FINISHED = gql`
subscription ApiPortStatusForChargingAndFinished ($port_status: String!,$facility_id: [bigint!]!){
	view_count_chargerport_status_aggregate(where: {facility_id: {_in: $facility_id}, port_status: {_eq: $port_status},charger_status: {_eq: true}}){
		aggregate {
		  sum {
			count
		  }
		}
		nodes {
			charger_name
			connector_id
			facility_name
			port_status
			updated_at
			user_name
			token
			transaction_id
			session_id
		  }
	  }
}
`;

export const API_PORT_STATUS_FOR_CHARGING_AND_FINISHED_AND_POWER_TYPE = gql`
subscription ApiPortStatusForChargingAndFinished ($port_status: String!,$facility_id: [bigint!]!, $power_type: String!){
	view_count_chargerport_status_aggregate(where: {facility_id: {_in: $facility_id}, port_status: {_eq: $port_status},charger_status: {_eq: true}, power_type: {_eq: $power_type}}){
		aggregate {
		  sum {
			count
		  }
		}
		nodes {
			charger_name
			connector_id
			facility_name
			port_status
			updated_at
			user_name
			token
			transaction_id
			session_id
		  }
	  }
}
`;

export const API_PORT_STATUS_WITH_FACILITY = gql`
subscription ApiPortStatusWithFacility($port_status: String! ,$facility_id: bigint!){
	view_count_port_status_aggregate(where: { facility_id: {_eq: $facility_id} , port_status: {_eq: $port_status},charger_status: {_eq: true}}){
		aggregate {
			sum {
				count
			  }
		}
		nodes {
			charger_name
			connector_id
			facility_name
			port_status
			updated_at
		  }
	  }
}
`;
export const API_PORT_STATUS_WITH_FACILITY_AND_POWER_TYPE = gql`
subscription ApiPortStatusWithFacility($port_status: String! ,$facility_id: bigint!, $power_type:String!){
	view_count_port_status_aggregate(where: { facility_id: {_eq: $facility_id} , port_status: {_eq: $port_status},charger_status: {_eq: true}, power_type: {_eq: $power_type}}){
		aggregate {
			sum {
				count
			  }
		}
		nodes {
			charger_name
			connector_id
			facility_name
			port_status
			updated_at
		  }
	  }
}
`;

export const API_PORT_STATUS_FOR_CHARGING_AND_FINISHED_WITH_FACILITY = gql`
subscription ApiPortStatusForChargingAndFinishedWithFacility($port_status: String! ,$facility_id: bigint!){
	view_count_chargerport_status_aggregate(where: { facility_id: {_eq: $facility_id} , port_status: {_eq: $port_status},charger_status: {_eq: true}}){
		aggregate {
			sum {
				count
			  }
		}
		nodes {
			charger_name
			connector_id
			facility_name
			port_status
			updated_at
			user_name
			token
			transaction_id
			session_id
		  }
	  }
}
`;

export const API_PORT_STATUS_FOR_CHARGING_AND_FINISHED_WITH_FACILITY_AND_POWER_TYPE = gql`
subscription ApiPortStatusForChargingAndFinishedWithFacility($port_status: String! ,$facility_id: bigint!, $power_type: String!){
	view_count_chargerport_status_aggregate(where: { facility_id: {_eq: $facility_id} , port_status: {_eq: $port_status},charger_status: {_eq: true}, power_type: {_eq: $power_type}}){
		aggregate {
			sum {
				count
			  }
		}
		nodes {
			charger_name
			connector_id
			facility_name
			port_status
			updated_at
			user_name
			token
			transaction_id
			session_id
		  }
	  }
}
`;

export const API_PORT_STATUS_OFFLINE_WITH_ALL = gql`
subscription ApiPortStatusWithFacility($facility_id: [bigint!]!){
	view_count_port_status_aggregate(where: { facility_id: {_in: $facility_id} ,charger_status: {_eq: false}}){
		aggregate {
			sum {
				count
			  }
		}
		nodes {
			charger_name
			connector_id
			facility_name
			port_status
			updated_at
		  }
	  }
}
`;
export const API_PORT_STATUS_OFFLINE_WITH_ALL_AND_POWER_TYPE = gql`
subscription ApiPortStatusWithFacility($facility_id: [bigint!]!, $power_type: String!){
	view_count_port_status_aggregate(where: { facility_id: {_in: $facility_id} ,charger_status: {_eq: false}, power_type: {_eq: $power_type}}){
		aggregate {
			sum {
				count
			  }
		}
		nodes {
			charger_name
			connector_id
			facility_name
			port_status
			updated_at
		  }
	  }
}
`;
export const API_PORT_STATUS_OFFLINE_WITH_FACILITY = gql`
subscription ApiPortStatusWithFacility($facility_id: bigint!){
	view_count_port_status_aggregate(where: { facility_id: {_eq: $facility_id} ,charger_status: {_eq: false}}){
		aggregate {
			sum {
				count
			  }
		}
		nodes {
			charger_name
			connector_id
			facility_name
			port_status
			updated_at
		  }
	  }
}
`;
export const API_PORT_STATUS_OFFLINE_WITH_FACILITY_AND_POWER_TYPE = gql`
subscription ApiPortStatusWithFacility($facility_id: bigint!, $power_type: String!){
	view_count_port_status_aggregate(where: { facility_id: {_eq: $facility_id} ,charger_status: {_eq: false}, power_type: {_eq: $power_type}}){
		aggregate {
			sum {
				count
			  }
		}
		nodes {
			charger_name
			connector_id
			facility_name
			port_status
			updated_at
		  }
	  }
}
`;

export const API_PORT_TYPE_SUBSCRIPTION = gql`
subscription ApiPortTypeSubscription($port_type: String!, $facility_id: [bigint!]!){
	view_count_port_type_aggregate(where: {port_type: {_eq: $port_type}, facility_id: {_in: $facility_id}}) {
		aggregate {
			sum{
		  count
			}
		}
		    nodes {
				charger_name
				connector_id
				facility_name
				last_updated_at
				power_type
			}
	  }
}
`;

export const API_PORT_TYPE_FACILITY_SUBSCRIPTION = gql`
subscription ApiPortTypeFacilitySubscription($port_type: String!, $facility_id: bigint!){
	view_count_port_type_aggregate(where: {port_type: {_eq: $port_type}, facility_id: {_eq: $facility_id}}) {
		aggregate {
			sum{
		  count
			}
		}
		nodes {
			charger_name
			connector_id
			facility_name
			last_updated_at
			power_type
		}
	  }
}
`;

export const API_TRANSACTIONS_AMOUNT_WITH_ALL_SUBSCRIPTION = gql`
subscription ApiTransactionsAmountWithAllSubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: [bigint!]!){
	view_facility_revenue(where: {created_at: {_gte: $start, _lte: $end}, facility_id: {_in: $facility_id}}){
		created_at
		total_amount_spent
	  }
}
`;

export const API_TRANSACTIONS_AMOUNT_FACILITY_SUBSCRIPTION = gql`
subscription ApiTransactionsAmountFacilitySubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: bigint!){
	view_facility_revenue(where: {created_at: {_gte: $start, _lte: $end}, facility_id: {_eq: $facility_id}}){
		created_at
		total_amount_spent
	  }
}
`;

export const API_CHART_DATA_WITH_ALL_SUBSCRIPTION = gql`
subscription ApiChartDataWithAllSubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: [bigint!]!){
	view_facility_revenue(where: {created_at: {_gte: $start, _lte: $end}, facility_id: {_in: $facility_id}}){
		created_at
		total_amount_spent
		units_consumed
		meter_start_at
		meter_stop_at
		power_type
		facility_id
	  }
}
`;
export const API_CHART_DATA_WITH_ALL_AND_POWER_TYPE_SUBSCRIPTION = gql`
subscription ApiChartDataWithAllSubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: [bigint!]!, $power_type: String!){
	view_facility_revenue(where: {created_at: {_gte: $start, _lte: $end}, facility_id: {_in: $facility_id}, power_type: {_eq: $power_type}}){
		created_at
		total_amount_spent
		units_consumed
		meter_start_at
		meter_stop_at
		power_type
		facility_id
	  }
}
`;
export const API_CHART_DATA_WITHOUT_ALL_SUBSCRIPTION = gql`
subscription ApiChartDataWithoutAllSubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: bigint!){
	view_facility_revenue(where: {created_at: {_gte: $start, _lte: $end}, facility_id: {_eq: $facility_id}}){
		created_at
		total_amount_spent
		units_consumed
		meter_start_at
		meter_stop_at
		power_type
		facility_id
	  }
}
`;
export const API_CHART_DATA_WITHOUT_ALL_AND_POWER_TYPE_SUBSCRIPTION = gql`
subscription ApiChartDataWithoutAllSubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: bigint!, $power_type: String!){
	view_facility_revenue(where: {created_at: {_gte: $start, _lte: $end}, facility_id: {_eq: $facility_id}, power_type: {_eq: $power_type}}){
		created_at
		total_amount_spent
		units_consumed
		meter_start_at
		meter_stop_at
		power_type
		facility_id
	  }
}
`;

export const API_ACTIVE_USERS_SUBSCRIPTION = gql`
subscription ApiActiveUsersSubscription($start_at: String!){
	api_chargersession_aggregate(where: {start_at: {_gte: $start_at}}) {
		aggregate {
		  count
		}
	  }
}
`;

export const API_CHARGER_OPERATION_SUBSCRIPTION = gql`
subscription ApiChargerOperationSubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: [bigint!]!){
	view_charger_operations_aggregate(where: {last_used: {_gte: $start, _lte: $end}, facility_id: {_in: $facility_id}}) {
		aggregate {
		  count
		}
	  }
		    nodes {
      facility_name
      last_used
      port_status
      charger_name
    }
}
`;
export const API_CHARGER_OPERATION_FACILITY_SUBSCRIPTION = gql`
subscription ApiChargerOperationSubscription($start: timestamptz! ,$end: timestamptz! $facility_id: bigint!){
	view_charger_operations_aggregate(where: {last_used: {_gte: $start, _lte: $end} ,facility_id: {_eq: $facility_id}}) {
		aggregate {
		  count
		}
	  }
		    nodes {
      facility_id
      last_used
      port_status
      charger_name
    }
}
`;

export const API_CHARGER_NOT_USED_OPERATION_SUBSCRIPTION = gql`
subscription ApiChargerNotUsedOperationSubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: [bigint!]!){
	view_charger_operations_aggregate(where: {_not: {last_used: {_gte: $start, _lte: $end}}, facility_id: {_in: $facility_id}}) {
		aggregate {
		  count
		}
	  }
}
`;

export const API_CHARGER_NOT_USED_FACILITY_OPERATION_SUBSCRIPTION = gql`
subscription ApiChargerNotUsedOperationSubscription($start: timestamptz! ,$end: timestamptz!, $facility_id: bigint!){
	view_charger_operations_aggregate(where: {_not: {last_used: {_gte: $start, _lte: $end}}, facility_id: {_eq: $facility_id}}) {
		aggregate {
		  count
		}
	  }
}
`;

export const API_TOTAL_FACILITY_SUBSCRIPTION = gql`
subscription ApiTotalFacilitySubscription{
	api_facility_aggregate {
		aggregate {
		  count
		}
	  }
}
`;

export const API_FACILITY_COUNT_SUBSCRIPTION = gql`
subscription ApiFacilityCountSubscription($facility_id: [bigint!]!){
	api_state {
		api_facilities_aggregate(where: {id: {_in: $facility_id}}) {
		  aggregate {
			count(columns: id)
		  }
		}
		name
	  }
}
`;
export const API_CURRENCY_SUBSCRIPTION = gql`
subscription ApiCurrencySubscription($token: String!){
api_user(where: {token: {_eq: $token}}) {
	api_country {
		currency
	  }
    }
  }
`;

export const API_CHARGER_GRAPH_SUBSCRIPTION = gql`
subscription ApiCurrencyGraphSubscription($start: timestamptz! ,$end: timestamptz!,$facility_id: bigint!){
	view_facility_revenue(where: {units_consumed: {_is_null: false}, facility_id: {_eq: $facility_id}, created_at: {_gte: $start, _lte: $end}}) {
		charger_name
		connector_id
		units_consumed
	  }
	}
`;
export const API_CHARGER_GRAPH_WITH_POWER_TYPE_SUBSCRIPTION = gql`
subscription ApiCurrencyGraphSubscription($start: timestamptz! ,$end: timestamptz!,$facility_id: bigint!, $power_type: String!){
	view_facility_revenue(where: {units_consumed: {_is_null: false}, facility_id: {_eq: $facility_id}, created_at: {_gte: $start, _lte: $end}, power_type: {_eq: $power_type}}) {
		charger_name
		connector_id
		units_consumed
	  }
	}
`;


export const API_CHARGER_AND_PORT = gql`
	subscription ApiChargerAndPortSubscription($chargerId: bigint!, $portId: bigint!) {
		api_charger_by_pk(id: $chargerId) {
			is_connected
			status
			api_chargerports(where: { id: { _eq: $portId } }) {
				port_status
			}
		}
	}
`;