import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import { Box, Typography, Grid } from "@mui/material";
import { useSubscription } from "@apollo/client";
import {
  API_PORT_STATUS,
  API_PORT_STATUS_AND_POWER_TYPE,
  API_PORT_STATUS_FOR_CHARGING_AND_FINISHED,
  API_PORT_STATUS_FOR_CHARGING_AND_FINISHED_AND_POWER_TYPE,
  API_PORT_STATUS_FOR_CHARGING_AND_FINISHED_WITH_FACILITY,
  API_PORT_STATUS_FOR_CHARGING_AND_FINISHED_WITH_FACILITY_AND_POWER_TYPE,
  API_PORT_STATUS_OFFLINE_WITH_ALL,
  API_PORT_STATUS_OFFLINE_WITH_ALL_AND_POWER_TYPE,
  API_PORT_STATUS_OFFLINE_WITH_FACILITY,
  API_PORT_STATUS_OFFLINE_WITH_FACILITY_AND_POWER_TYPE,
  API_PORT_STATUS_WITH_FACILITY,
  API_PORT_STATUS_WITH_FACILITY_AND_POWER_TYPE
} from "../../graphql/query";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import DynamicTable from "../customComponents/DynamicTable";
import CustomTabs from "../customComponents/CustomTabs";
import CustomModal from "../customComponents/customModal";
import ExportExcel from "../buttonComponents/ExportExcel";
import * as XLSX from "xlsx";

const size = {
  //   width: 400,
  height: 190,
  // margin: { left: 80 },
};

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 20,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2} sx={{ fontSize: '15px', fontWeight: 500, color: '#979DAC' }}>
      {children}
    </StyledText>
  );
}


const ChargeUsageStatue = ({ facilityFilterID, facilityFilterName, facilityFilterAddress, showTitle, powerTypeFilter }) => {

  const api = facilityFilterName === 'All' ? 
  powerTypeFilter === "Both" || !powerTypeFilter ? API_PORT_STATUS : API_PORT_STATUS_AND_POWER_TYPE
  : 
  powerTypeFilter === "Both" || !powerTypeFilter ?  API_PORT_STATUS_WITH_FACILITY: API_PORT_STATUS_WITH_FACILITY_AND_POWER_TYPE;

  const apiOffline = facilityFilterName === 'All' ? 
  powerTypeFilter === "Both" || !powerTypeFilter ? API_PORT_STATUS_OFFLINE_WITH_ALL : API_PORT_STATUS_OFFLINE_WITH_ALL_AND_POWER_TYPE
  : 
  powerTypeFilter === "Both" || !powerTypeFilter ? API_PORT_STATUS_OFFLINE_WITH_FACILITY: API_PORT_STATUS_OFFLINE_WITH_FACILITY_AND_POWER_TYPE;

  const apiForChargingAndFinished = facilityFilterName === 'All' ? 
  powerTypeFilter === "Both" || !powerTypeFilter ?  API_PORT_STATUS_FOR_CHARGING_AND_FINISHED : API_PORT_STATUS_FOR_CHARGING_AND_FINISHED_AND_POWER_TYPE
  : 
  powerTypeFilter === "Both" || !powerTypeFilter ? API_PORT_STATUS_FOR_CHARGING_AND_FINISHED_WITH_FACILITY : API_PORT_STATUS_FOR_CHARGING_AND_FINISHED_WITH_FACILITY_AND_POWER_TYPE

  const power_type_query = powerTypeFilter === "Both" || !powerTypeFilter ? {} : {power_type: powerTypeFilter}

  const { data: data1 } = useSubscription(api, {
    variables:
    {
      port_status: "Available",
      facility_id: facilityFilterID,
      ...power_type_query
    }
  });
  const { data: data2 } = useSubscription(api, {
    variables: {
      port_status: "Preparing",
      facility_id: facilityFilterID,
      ...power_type_query
    }
  })
  const { data: data3 } = useSubscription(apiForChargingAndFinished, {
    variables: {
      port_status: "Charging",
      facility_id: facilityFilterID,
      ...power_type_query
    }
  })
  const { data: data4 } = useSubscription(apiForChargingAndFinished, {
    variables:
    {
      port_status: "Finishing",
      facility_id: facilityFilterID,
      ...power_type_query
    }
  })
  const { data: data5 } = useSubscription(api, {
    variables: {
      port_status: "Faulted",
      facility_id: facilityFilterID,
      ...power_type_query
    }
  })
  const { data: data6 } = useSubscription(apiOffline, {
    variables: {
      facility_id: facilityFilterID,
      ...power_type_query
    }
  });

  const [data, setData] = useState([{ label: '', value: 0 }]);

  const { t } = useTranslation();

  const [tab, setTab] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState(null)

  useEffect(() => {
    setData([
      { label: 'Available', value: data1?.view_count_port_status_aggregate?.aggregate?.sum?.count ?? 0, table: data1?.view_count_port_status_aggregate?.nodes ?? [] },
      { label: 'Preparing', value: data2?.view_count_port_status_aggregate?.aggregate?.sum?.count ?? 0, table: data2?.view_count_port_status_aggregate?.nodes ?? [] },
      { label: 'Charging', value: data3?.view_count_chargerport_status_aggregate?.aggregate?.sum?.count ?? 0, table: data3?.view_count_chargerport_status_aggregate?.nodes ?? [] },
      { label: 'Finishing', value: data4?.view_count_chargerport_status_aggregate?.aggregate?.sum?.count ?? 0, table: data4?.view_count_chargerport_status_aggregate?.nodes ?? [] },
      { label: 'Faulted', value: data5?.view_count_port_status_aggregate?.aggregate?.sum?.count ?? 0, table: data5?.view_count_port_status_aggregate?.nodes ?? [] },
      { label: 'Offline', value: data6?.view_count_port_status_aggregate?.aggregate?.sum?.count ?? 0, table: data6?.view_count_port_status_aggregate?.nodes ?? [] }
    ])

  }, [data1, data2, data3, data4, data5, data6, facilityFilterID])

  const totalChargers = data?.reduce((acc, item) => acc + item?.value, 0);

  const openStatusModal = (e, d) => {
    setTab(d.dataIndex);
    setOpenModal(true);
  }

  function formatDate(date) {
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    let yyyy = date.getFullYear();
    let hh = String(date.getHours()).padStart(2, '0');
    let min = String(date.getMinutes()).padStart(2, '0');
    let ss = String(date.getSeconds()).padStart(2, '0');

    return `${dd}-${mm}-${yyyy} ${hh}:${min}:${ss}`;
  }


  const formateTableData = (toFormateData) => {

    const formateData = (content, type) => {
      if (type !== "Charging" && type !== "Finishing")
        return content?.map(item => ({
          charger_name: item?.charger_name,
          connector_id: item?.connector_id,
          facility_name: item?.facility_name,
          last_updated_at: item?.updated_at ? formatDate(new Date(item?.updated_at)) : ''
        }))
      return content?.map(item => ({
        charger_name: item?.charger_name,
        connector_id: item?.connector_id,
        facility_name: item?.facility_name,
        user_name: item?.user_name,
        transaction_id: item?.transaction_id,
        last_updated_at: item?.updated_at ? formatDate(new Date(item?.updated_at)) : '',
        session_id: item?.session_id,
        login: {
          target: "_blank",
          type: "button",
          api: `${process.env.REACT_APP_WEBAPP_URL}charger-status/${item?.session_id}?token=${item?.token}`,
          label: "Open"
        }
      }))
    }

    const headers = [
      { label: 'charger', id: 'charger_name' },
      { label: 'connector id', id: 'connector_id' },
      { label: 'facility', id: 'facility_name' },
      { label: 'last updated at', id: 'last_updated_at' },
    ]

    const chargingAndFinishingHeader = [
      { label: 'charger', id: 'charger_name' },
      { label: 'connector id', id: 'connector_id' },
      { label: 'facility', id: 'facility_name' },
      { label: 'transaction id', id: 'transaction_id' },
      { label: 'session id', id: 'session_id' },
      { label: 'user name', id: 'user_name' },
      { label: 'last updated at', id: 'last_updated_at' },
      { label: 'open', id: 'login' }
    ]

    setTableData({
      type: "table",
      name: null,
      tabs: toFormateData.map(item => ({
        name: item.label,
        headers: item.label !== "Charging" && item.label !== "Finishing" ? headers : chargingAndFinishingHeader,
        table_content: formateData(item.table, item.label),
        count: item.table?.length ?? 0
      }))
    })
  }

  useEffect(() => {
    if (data?.length > 0)
      formateTableData(data);
  }, [data])

  const handleExportExcel = () => {
    if (!tableData || !tableData?.tabs) return;
  
    const workbook = XLSX.utils.book_new(); 
  
    tableData?.tabs?.forEach((tab) => {
      if (tab.table_content.length > 0) {
        const filteredData = tab.table_content.map(({ charger_name, connector_id, facility_name, last_updated_at }) => ({
          charger: charger_name,
          "connector id": connector_id,
          facility: facility_name,
          "last updated at": last_updated_at,
        }));
  
        const worksheet = XLSX.utils.json_to_sheet(filteredData); 
        XLSX.utils.book_append_sheet(workbook, worksheet, tab.name); 
      }
    });
    if(workbook.SheetNames.length > 0)
    XLSX.writeFile(workbook, `ChargerStatus_AllTabs.xlsx`); 
  };


  return (
    <>
      <Box bgcolor={"white"} borderRadius={"16px"} padding={"15px"}>
        <Box>
          {showTitle ? (
            <>
              <Typography variant="h5" color={"primary"} sx={{ textTransform: 'capitalize', fontWeight: '600', fontSize: '16px' }}>{facilityFilterName}</Typography>
              <Typography variant="body1" color={"gray"} sx={{ textTransform: 'capitalize', fontSize: '10px' }}>{facilityFilterAddress}</Typography>
            </>
          ) : (
            <Typography sx={{ textTransform: 'capitalize', fontWeight: '600' }}>{t('chargerStatus')}</Typography>

          )}
        </Box>
        <Box style={{ padding: "22px 0px" }}>
          <PieChart
            series={[{ data, innerRadius: 66, outerRadius: 76 }]}
            slotProps={{
              // legend: { hidden: true },
            }}
            onClick={openStatusModal}
            {...size}
          >
            <PieCenterLabel>{totalChargers} {t('chargers')}</PieCenterLabel>
          </PieChart>
        </Box>
        <Box>
          {
            data?.map((item, index) => <Typography
              display={"flex"}
              justifyContent={"space-between"}
              marginBottom={2}
              key={index}
            >
              <Typography variant="subtitle" sx={{ fontSize: '12px', fontWeight: '500', color: '#5C677D' }} >{item?.label}</Typography>
              <Typography variant="subtitle" sx={{ fontSize: '12px', fontWeight: '600', color: '#001233' }}>{item?.value}</Typography>
            </Typography>)}
        </Box>
      </Box>
      {openModal &&
        (
          <>
            <CustomModal open={openModal} onClose={() => setOpenModal(false)} bgColor='white' width='50vw' overflow='clip'>
              <Grid sx={{ paddingInline: '16px' }}>
                <CustomTabs
                  formTabs={tableData?.tabs}
                  tabValue={tab}
                  handleTabChange={(e, newValue) => setTab(newValue)}
                  showCount={true}
                />
              </Grid>
              <Grid container justifyContent='flex-start' m={2}>
                <ExportExcel key='10' onClick={handleExportExcel} />
              </Grid>
              <DynamicTable
                setSelectedData={setTab}
                tabValue={tab}
                searchedData={tableData?.tabs[tab]?.table_content}
                tabs={tableData?.tabs}
                maxHeight='calc(70vh - 145px)'
              />
            </CustomModal>
          </>
        )}
    </>
  );
};

export default ChargeUsageStatue;
