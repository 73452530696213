import { useSubscription } from "@apollo/client";
import UnitConsumedIcon from "../components/dashboard/icons/UnitConsumed";
import { API_UNITS_CONSUMED_ALL_AND_POWER_TYPE_SUBSCRIPTION, API_UNITS_CONSUMED_ALL_SUBSCRIPTION, API_UNITS_CONSUMED_AND_POWER_TYPE_SUBSCRIPTION, API_UNITS_CONSUMED_SUBSCRIPTION, API_UNITS_CONSUMED_WITH_ALL_FACILITY_AND_POWER_TYPE_SUBSCRIPTION, API_UNITS_CONSUMED_WITH_ALL_FACILITY_SUBSCRIPTION } from "../graphql/query";
import { useState } from "react";
import { useEffect } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import * as XLSX from "xlsx";

const UnitsConsumed = ({ dateFilter, facilityFilter, powerTypeFilter }) => {

    const api = facilityFilter?.name === 'All' ?
        powerTypeFilter === "Both" || !powerTypeFilter ? API_UNITS_CONSUMED_WITH_ALL_FACILITY_SUBSCRIPTION : API_UNITS_CONSUMED_WITH_ALL_FACILITY_AND_POWER_TYPE_SUBSCRIPTION
        :
        powerTypeFilter === "Both" || !powerTypeFilter ? API_UNITS_CONSUMED_SUBSCRIPTION : API_UNITS_CONSUMED_AND_POWER_TYPE_SUBSCRIPTION;
    const power_type_query = powerTypeFilter === "Both" || !powerTypeFilter ? {} : { power_type: powerTypeFilter }
    const variables = {
        variables: {
            start: dateFilter?.start?.toISOString(),
            end: dateFilter?.end?.toISOString(),
            facility_id: facilityFilter?.id,
            ...power_type_query
        }
    }

    const { data } = useSubscription(api, variables);
    const [totalHoursConsumed, setTotalHoursConsumed] = useState(0);
    const [totalUnitsConsumed, setTotalUnitsConsumed] = useState(0);
    const [loading, setLoading] = useState(true);

    const { data:allData } = useSubscription(  powerTypeFilter === "Both" ? API_UNITS_CONSUMED_ALL_SUBSCRIPTION : API_UNITS_CONSUMED_ALL_AND_POWER_TYPE_SUBSCRIPTION , variables)

    const { t } = useTranslation();
    const [id, setId] = useState(facilityFilter?.id)

    useEffect(() => setId(facilityFilter?.id), [facilityFilter])


    useEffect(() => {
        setTotalHoursConsumed(0);
        setTotalUnitsConsumed(0);
    }, [id, dateFilter])

    useEffect(() => {
        setLoading(true);
        if (data?.view_facility_revenue_aggregate?.aggregate?.sum) {
            let hours_consumed = (data?.view_facility_revenue_aggregate?.aggregate?.sum?.hours_consumed ?? 0);
            let units_consumed = (data?.view_facility_revenue_aggregate?.aggregate?.sum?.units_consumed ?? 0);
            hours_consumed = hours_consumed === 0 ? '0' : hours_consumed.toFixed(3);
            units_consumed = units_consumed === 0 ? '0' : (units_consumed / 1000).toFixed(3);
            setTotalHoursConsumed(Number(hours_consumed).toLocaleString());
            setTotalUnitsConsumed(Number(units_consumed).toLocaleString());
        }
        setLoading(false)
    }, [data, facilityFilter, dateFilter]);

    const getFacilityWiseTotalUnitsAndHoursConsumed = () => {
        const unprocessedFacilityList = facilityFilter.id;
        const facilityList = [];
        unprocessedFacilityList?.forEach(item => facilityList.push({
            name: '',
            id: item,
            total_hours_consumed: 0,
            total_units_consumed: 0,
            from: dateFilter?.start?.toISOString(),
            to: dateFilter?.end?.toISOString()
        }))
        if (allData?.view_facility_revenue_aggregate?.nodes?.length > 0 && facilityList?.length > 0) {
            facilityList.forEach(item => {
                allData.view_facility_revenue_aggregate.nodes.forEach(value => {
                    if (value?.facility_id === item?.id) {
                        item.name = value?.facility_name;
                        item.total_hours_consumed = Number(((value?.hours_consumed ?? 0) + item.total_hours_consumed).toFixed(3));
                        item.total_units_consumed = Number((((value?.units_consumed ?? 0) + item.total_units_consumed) / 1000).toFixed(3));
                    }
                })
            })
        }
        return facilityList;
    }
    
    const handleDowloadExcelSheet = () => {

        const workbook = XLSX.utils.book_new();
        let data=[];
        let fileName = '';

        if (facilityFilter.name === "All") {
             data = getFacilityWiseTotalUnitsAndHoursConsumed();
             fileName = "All-report"
        }
        else {
             data = [{
                name: facilityFilter?.name,
                total_units_consumed: totalUnitsConsumed,
                total_hours_consumed: totalHoursConsumed,
                from: dateFilter?.start?.toISOString(),
                to: dateFilter?.end?.toISOString()
            }]
            fileName = `${data[0].facility_name.length > 20 ? data[0].facility_name.slice(0, 20) : data[0].facility_name}-report`
        }

        const worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet,fileName );

        if (workbook.SheetNames.length > 0) {
            const sheet_name = `${fileName}.xlsx`;
            XLSX.writeFile(workbook, sheet_name);
        }
    }

    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            bgcolor={"white"}
            justifyContent={"space-between"}
            borderRadius={"16px"}
            padding={"24px"}
            height='150px'
            position="relative"
        >
            <Typography>
                <Typography variant="subtitle" fontSize='12px' fontWeight='600' style={{ textWrap: 'nowrap' }}>{t('hoursConsumed')} {loading ? (<span style={{ color: '#467CEB' }}> {t('loading')}...</span>) : ''}</Typography>
                <Typography variant="h6" fontSize='18px' fontWeight='700' sx={{ marginBottom: '10px' }} style={{ textWrap: 'nowrap' }}>{totalHoursConsumed} <span style={{ fontSize: '12px' }}>Hours</span></Typography>
                <Typography variant="subtitle" fontSize='12px' fontWeight='600' style={{ textWrap: 'nowrap' }}>{t('unitsConsumed')} {loading ? (<span style={{ color: '#467CEB' }}> {t('loading')}...</span>) : ''}</Typography>
                <Typography variant="h6" fontSize='18px' fontWeight='700' style={{ textWrap: 'nowrap' }}>{totalUnitsConsumed} <span style={{ fontSize: '12px' }}>kWh</span></Typography>
            </Typography>
            {
                 !loading &&
                <Tooltip title="Download Report" sx={{ position: "absolute", top: "-3px", right: "0px", color: "grey" }} >
                    <IconButton>
                        <Box onClick={handleDowloadExcelSheet} >
                            <ArrowDownwardIcon />
                        </Box>
                    </IconButton>
                </Tooltip>
            }
            <UnitConsumedIcon />
        </Box>
    );
}
export default UnitsConsumed;