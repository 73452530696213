import { useDispatch, useSelector } from "react-redux";
import { Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { changeFilter } from "../slice/PowerTypeFilterSlice";

const  PowerTypeFilter= () => {
  const dispatch = useDispatch();
  const { list, appliedFilter } = useSelector((state) => state.powerTypeFilter);

  const changeHandler = (e) => {
    const selectedPowerType = e.target.value;
    dispatch(changeFilter(selectedPowerType))
  };

  return (
    <Grid container>
      <FormControl sx={{ minWidth: "150px" }}>
        <InputLabel id="month-select-label">Select Power Type </InputLabel>
        <Select
          labelId="month-select-label"
          label="Select Power Type "
          value={appliedFilter}
          onChange={changeHandler}
          sx={{ height: "49px", backgroundColor: "white" }}
          style={{ fontSize: "15px" }}
        >
          {list &&
            list.map((item, index) => (
              <MenuItem key={index} value={item} sx={{ fontSize: "15px" }}>
                {item}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Grid>
  );
};
export default PowerTypeFilter;
