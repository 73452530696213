import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import api from '../../apiSetup/apiCall';
import { toast } from 'react-toastify';
import { fetchPaymentsAndTransactionsData } from '../../slice/PaymentsAndTransactionsSlice';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: "10px",
    outline: "none"
};


const RefundModal = ({ open, onClose, openRefundModalDetails }) => {


    const { currency } = useSelector((state) => state.user);
    const [transactionId, setTransactionId] = useState('');
    const dispatch = useDispatch();
    const { selectedDate } = useSelector(state => state.datePicker);
    const {t} = useTranslation();

    const callRefundApi = async (e) => {
        try {
            const response = await api.get(openRefundModalDetails?.api)
            if (response.data.success) {
                toast.success("Refund Under Process!")
                onClose();
                const startISOString = selectedDate?.startForDetails?.toISOString()?.split('T')[0];
                const endISOString = selectedDate?.end?.toISOString()?.split('T')[0];
                dispatch(fetchPaymentsAndTransactionsData({ startDate: startISOString, endDate: endISOString }));
            }
        }
        catch (error) {
            console.log(error);
            toast.error("Please retry")
        }
    }

    const closeRefundModal = () =>{
        setTransactionId('');
        onClose();
    }

    return <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Grid container sx={{ paddingBottom: "6px" , borderBottom: "1px solid #EAEBEE", marginBottom: "15px"}} justifyContent='space-between' alignItems='center'>
                <Grid item>
                    <Typography id="modal-modal-title" component="h2" sx={{ color: "#001233", fontWeight: "600", fontSize: "17px" }}>
                        {t('refundToWallet')}
                    </Typography>
                </Grid>
                <Button onClick={closeRefundModal} sx={{ color: 'black', padding: 0, minWidth: 'unset' }}>
                    <Close />
                </Button>
            </Grid>
            <Grid container>
                <Typography id="modal-modal-description" sx={{ fontSize: "14px", marginBottom: "6px" }}>
                    {`Are you sure want to refund the amount of ${currency}${openRefundModalDetails?.amount_to_be_refunded} to ${openRefundModalDetails?.user_to_be_refunded}?`}
                </Typography>
            </Grid>
            <Grid container>
                <Typography sx={{fontSize: '13px', display: 'flex', margin: '5px 0 3px 0'}}>
                {t('enterTransactionId')}
                    <Typography sx={{color: 'red'}}>
                        *
                    </Typography>
                </Typography>
                <TextField
                    variant="outlined"
                    value={transactionId}
                    onChange={e => setTransactionId(e.target.value)}
                    size="small"
                    sx={{ width: '100%', borderRadius: "300px !important"}}
                />
            </Grid>
            <Grid container sx={{ marginTop: '10px' }} justifyContent='flex-end'>
                <Button variant="outlined" onClick={closeRefundModal} sx={{ marginRight: '5px', borderRadius: "100px" }}> {t('cancel')}</Button>
                {
                    transactionId != openRefundModalDetails?.transaction_id ?
                        <Button
                            variant="contained"
                            disabled
                            sx={{ borderRadius: "100px"}}
                        >
                             {t('submit')}
                        </Button>
                        : <Button
                            variant="contained"
                            onClick={callRefundApi}
                            sx={{ borderRadius: "100px"}}
                        >
                             {t('submit')}
                        </Button>
                }
            </Grid>
        </Box>
    </Modal>
}
export default RefundModal;