import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ChargeUsageStatue from "../dashboard/ChargeUsageStatue";
import { useSubscription } from "@apollo/client";
import { API_PORT_TYPE_FACILITY_SUBSCRIPTION, API_PORT_TYPE_SUBSCRIPTION } from "../../graphql/query";
import ChargerOperationStatus from "./ChargerOperationStatus";
import RegisteredFacility from "./RegisterdFacility";
import { BharatacOn } from './icons/BHARATAC-on';
import { Ccs1On } from "./icons/CCS1-on";
import { Cccs2On } from "./icons/CCS2-on";
import { ChademoOn } from "./icons/CHADEMO-on";
import { GbtOn } from "./icons/GBT-on";
import { PlugpointOn } from "./icons/PLUGPOINT-on";
import { TeslaOn } from "./icons/TESLA-on";
import { Type1on } from "./icons/TYPE1-on";
import { Type2On } from "./icons/TYPE2-on";
import ProfileSection from "../ProfileSection";
import BharatdcOn from './icons/BHARATDC-on.svg';
import FacilityFilterBtn from "../FacilityFilter";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import CustomModal from "../customComponents/customModal";
import CustomTabs from "../customComponents/CustomTabs";
import ExportExcel from "../buttonComponents/ExportExcel";
import DynamicTable from "../customComponents/DynamicTable";
import * as XLSX from "xlsx";

export default function StationManagement() {

    const { selectedFacility } = useSelector(state => state.stationList);
    const [chargerDetails, setChargerDetails] = useState(null);
    const [boxColor, setBoxColor] = useState('white');
    const apiPortType = selectedFacility?.name === 'All' ? API_PORT_TYPE_SUBSCRIPTION : API_PORT_TYPE_FACILITY_SUBSCRIPTION;

    const { t } = useTranslation();

    const { data } = useSubscription(
        apiPortType, {
        variables: {
            port_type: 'TYPE2',
            facility_id: selectedFacility?.id
        }
    }
    )
    const { data: data1 } = useSubscription(
        apiPortType, {
        variables: {
            port_type: 'TYPE1',
            facility_id: selectedFacility?.id
        }
    }
    )
    const { data: data2 } = useSubscription(
        apiPortType, {
        variables: {
            port_type: 'BHARATAC',
            facility_id: selectedFacility?.id
        }
    }
    )
    const { data: data3 } = useSubscription(
        apiPortType, {
        variables: {
            port_type: 'PLUGPOINT',
            facility_id: selectedFacility?.id
        }
    }
    )
    const { data: data4 } = useSubscription(
        apiPortType, {
        variables: {
            port_type: 'CCS1',
            facility_id: selectedFacility?.id
        }
    }
    )
    const { data: data5 } = useSubscription(
        apiPortType, {
        variables: {
            port_type: 'CCS2',
            facility_id: selectedFacility?.id
        }
    }
    )
    const { data: data6 } = useSubscription(
        apiPortType, {
        variables: {
            port_type: 'BHARATDC',
            facility_id: selectedFacility?.id
        }
    }
    )
    const { data: data7 } = useSubscription(
        apiPortType, {
        variables: {
            port_type: 'TESLA',
            facility_id: selectedFacility?.id
        }
    }
    )
    const { data: data8 } = useSubscription(
        apiPortType, {
        variables: {
            port_type: 'CHADEMO',
            facility_id: selectedFacility?.id
        }
    }
    )
    const { data: data9 } = useSubscription(
        API_PORT_TYPE_SUBSCRIPTION, {
        variables: {
            port_type: 'GBT',
            facility_id: selectedFacility?.id
        }
    }
    )
    useEffect(() => {
        setChargerDetails([
            { label: 'TYPE2', value: data?.view_count_port_type_aggregate?.aggregate?.sum?.count ?? 0, svg: <Type2On /> , table:  data?.view_count_port_type_aggregate?.nodes ?? [], index: 0},
            { label: 'TYPE1', value: data1?.view_count_port_type_aggregate?.aggregate?.sum?.count ?? 0, svg: <Type1on /> , table:  data1?.view_count_port_type_aggregate?.nodes ?? [], index: 1},
            { label: 'BHARATAC', value: data2?.view_count_port_type_aggregate?.aggregate?.sum?.count ?? 0, svg: <BharatacOn /> , table:  data2?.view_count_port_type_aggregate?.nodes ?? [], index: 2},
            { label: 'PLUGPOINT', value: data3?.view_count_port_type_aggregate?.aggregate?.sum?.count ?? 0, svg: < PlugpointOn /> , table:  data3?.view_count_port_type_aggregate?.nodes ?? [], index: 3},
            { label: 'CCS1', value: data4?.view_count_port_type_aggregate?.aggregate?.sum?.count ?? 0, svg: < Ccs1On /> , table:  data4?.view_count_port_type_aggregate?.nodes ?? [], index: 4},
            { label: 'CCS2', value: data5?.view_count_port_type_aggregate?.aggregate?.sum?.count ?? 0, svg: < Cccs2On /> , table:  data5?.view_count_port_type_aggregate?.nodes ?? [], index: 5},
            { label: 'BHARATDC', value: data6?.view_count_port_type_aggregate?.aggregate?.sum?.count ?? 0, svg: BharatdcOn , table:  data6?.view_count_port_type_aggregate?.nodes ?? [], index: 6},
            { label: 'TESLA', value: data7?.view_count_port_type_aggregate?.aggregate?.sum?.count ?? 0, svg: < TeslaOn /> , table:  data7?.view_count_port_type_aggregate?.nodes ?? [], index: 7},
            { label: 'CHADEMO', value: data8?.view_count_port_type_aggregate?.aggregate?.sum?.count ?? 0, svg: < ChademoOn /> , table:  data8?.view_count_port_type_aggregate?.nodes ?? [], index: 8},
            { label: 'GBT', value: data9?.view_count_port_type_aggregate?.aggregate?.sum?.count ?? 0, svg: < GbtOn /> , table:  data9?.view_count_port_type_aggregate?.nodes ?? [], index: 9},
        ]);
    }, [data, data1, data2, data3, data4, data5, data6, data7, data8, data9, selectedFacility])

    const [tab, setTab] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState(null);

    const openStatusModal = (e, d) => {
        setTab(d);
        setOpenModal(true);
    }

    function formatDate(date) {
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
        let yyyy = date.getFullYear();
        let hh = String(date.getHours()).padStart(2, '0');
        let min = String(date.getMinutes()).padStart(2, '0');
        let ss = String(date.getSeconds()).padStart(2, '0');

        return `${dd}-${mm}-${yyyy} ${hh}:${min}:${ss}`;
    }


    const formateTableData = (toFormateData) => {


        const formateData = (content, type) => {
            console.log('hellllooooo',content)
            return content?.map(item => ({
                charger_name: item?.charger_name,
                connector_id: item?.connector_id,
                facility_name: item?.facility_name,
                last_updated_at: item?.last_updated_at ? formatDate(new Date(item?.last_updated_at)) : '',
                power_type: item?.power_type
            }))
        }

        const headers = [
            { label: 'charger', id: 'charger_name' },
            { label: 'connector id', id: 'connector_id' },
            { label: 'facility', id: 'facility_name' },
            { label: 'last updated at', id: 'last_updated_at' },
            { label: 'charger type', id: 'power_type' }
        ]


        setTableData({
            type: "table",
            name: null,
            tabs: toFormateData.map(item => ({
                name: item.label,
                headers: headers,
                table_content: formateData(item.table, item.label),
                count: item.table?.length ?? 0
            }))
        })
    }

    useEffect(() => {
        if (chargerDetails?.length > 0)
            formateTableData(chargerDetails);
    }, [chargerDetails])

    const handleExportExcel = () => {
        if (!tableData || !tableData?.tabs) return;

        const workbook = XLSX.utils.book_new();

        tableData?.tabs?.forEach((tab) => {
            if (tab.table_content.length > 0) {
                const filteredData = tab.table_content.map(({ charger_name, connector_id, facility_name, last_updated_at }) => ({
                    charger: charger_name,
                    "connector id": connector_id,
                    facility: facility_name,
                    "last updated at": last_updated_at,
                    "charger_type": "power_type"
                }));

                const worksheet = XLSX.utils.json_to_sheet(filteredData);
                XLSX.utils.book_append_sheet(workbook, worksheet, tab.name);
            }
        });
        if(workbook.SheetNames.length > 0)
        XLSX.writeFile(workbook, `Charger_types.xlsx`);
    };

    return (
        <Box sx={{ flexBasis: 1, padding: '0px 0' }}>
            <Box>
                <Grid container rowSpacing={3} columnSpacing={3} alignItems='center' style={{ backgroundColor: "white", marginBottom: "20px" }}>
                    <Grid item xs={8} style={{ flexGrow: 1, padding: '0 20px', alignItems: 'center', display: "flex" }}>
                        <Typography variant="h4" fontSize='20px' fontWeight='600'>{t('stationManagementOverview')}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ paddingTop: "5px" }}>
                        <ProfileSection />
                    </Grid>
                </Grid>
                <Grid container justifyContent='space-between' mb={2} >
                    <Grid item>
                        <FacilityFilterBtn />
                    </Grid>
                </Grid>
                <Grid container
                    sx={{
                        flexWrap: { md: 'nowrap', xs: 'wrap' },
                        flexDirection: { md: 'column', lg: 'row' },
                        gap: '20px'
                    }} >
                    <Grid item lg={8} xs={12} display='flex' flexDirection='column'>
                        <ChargerOperationStatus boxColor={boxColor} facilityFilter={selectedFacility} />
                        <RegisteredFacility boxColor={boxColor} facilityFilter={selectedFacility} />
                    </Grid>
                    <Grid item lg={4} xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                        {
                            chargerDetails &&
                            <Grid item sx={{
                                backgroundColor: boxColor,
                                padding: '10px 20px',
                                borderRadius: '16px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'column',
                                marginBottom: '24px'
                            }}>
                                <Typography variant="h5" component='h5' sx={{ wordBreak: 'break-all', marginBottom: '24px', fontWeight: '600', fontSize: '20px' }}>
                                    {t('chargerType')}
                                </Typography>
                                <List sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '0' }}>
                                    {
                                        chargerDetails?.filter(item => item.value > 0).map((item, index) => <ListItem key={item?.index} sx={{ marginBottom: '14px', padding: '0', cursor: "pointer" }} onClick={(e)=>openStatusModal(e,item?.index)}>
                                            <Grid container justifyContent='space-between' flexWrap='nowrap' alignItems='center' >
                                                <Grid item sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                    <Grid item sx={{ width: '20px', height: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {
                                                            item.label !== 'BHARATDC'
                                                                ?
                                                                item?.svg
                                                                :
                                                                <img src={BharatdcOn} alt='bharatdc' width='20px' height='20px' />
                                                        }
                                                    </Grid>
                                                    <Typography variant='overline' sx={{ lineHeight: '18px', fontSize: '14px', fontWeight: '500', color: '#5C677D' }}>
                                                        {item?.label}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='subtitle2' component='span' sx={{ lineHeight: '18px', fontSize: '14px', fontWeight: '500', color: '#001233' }}>
                                                        {item?.value}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItem>)
                                    }
                                </List>
                            </Grid>
                        }
                        <Grid item sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: boxColor,
                            borderRadius: '16px',
                            padding: '20px',
                            alignItems: { lg: 'normal', xs: 'center' }
                        }}
                        >
                            <ChargeUsageStatue facilityFilterID={selectedFacility.id} facilityFilterName={selectedFacility.name} />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {openModal &&
                (
                    <>
                        <CustomModal open={openModal} onClose={() => setOpenModal(false)} bgColor='white' width='50vw' overflow='clip'>
                            <Grid sx={{ paddingInline: '16px' }}>
                                <CustomTabs
                                    formTabs={tableData?.tabs}
                                    tabValue={tab}
                                    handleTabChange={(e, newValue) => setTab(newValue)}
                                    showCount={true}
                                />
                            </Grid>
                            <Grid container justifyContent='flex-start' m={2}>
                                <ExportExcel key='10' onClick={handleExportExcel} />
                            </Grid>
                            <DynamicTable
                                setSelectedData={setTab}
                                tabValue={tab}
                                searchedData={tableData?.tabs[tab]?.table_content}
                                tabs={tableData?.tabs}
                                maxHeight='calc(70vh - 145px)'
                            />
                        </CustomModal>
                    </>
                )}
        </Box >
    );
}