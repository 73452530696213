import { useSubscription } from "@apollo/client"
import { useState } from "react"
import { useEffect } from "react"
import { API_CHARGER_NOT_USED_FACILITY_OPERATION_SUBSCRIPTION, API_CHARGER_NOT_USED_OPERATION_SUBSCRIPTION, API_CHARGER_OPERATION_FACILITY_SUBSCRIPTION, API_CHARGER_OPERATION_SUBSCRIPTION } from "../../graphql/query"
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import * as XLSX from "xlsx";
import CustomModal from "../customComponents/customModal";
import CustomTabs from "../customComponents/CustomTabs";
import ExportExcel from "../buttonComponents/ExportExcel";
import DynamicTable from "../customComponents/DynamicTable";

const calculateRange = (days) => {
    const today = new Date();
    const start = new Date(today);
    start.setDate(today.getDate() - days);
    return {
        start: start.toISOString().split('T')[0],
        end: today.toISOString().split('T')[0],
    };
}

const colors = ['indigo', 'lightGreen', 'lime', '#FFADAD'];

const ChargerOperationStatus = ({ boxColor, facilityFilter }) => {

    const [chargerStatus, setChargerStatus] = useState(null);

    const apiUsed = facilityFilter?.name === 'All' ? API_CHARGER_OPERATION_SUBSCRIPTION : API_CHARGER_OPERATION_FACILITY_SUBSCRIPTION;
    const apiNotUsed = facilityFilter?.name === 'All' ? API_CHARGER_NOT_USED_OPERATION_SUBSCRIPTION : API_CHARGER_NOT_USED_FACILITY_OPERATION_SUBSCRIPTION;

    const { t } = useTranslation();

    const { data } = useSubscription(apiUsed, {
        variables: {
            start: calculateRange(15).start,
            end: calculateRange(15).end,
            facility_id: facilityFilter?.id
        }
    })
    const { data: data1 } = useSubscription(apiUsed, {
        variables: {
            start: calculateRange(30).start,
            end: calculateRange(30).end,
            facility_id: facilityFilter?.id
        }
    })
    const { data: data2 } = useSubscription(apiUsed, {
        variables: {
            start: calculateRange(60).start,
            end: calculateRange(60).end,
            facility_id: facilityFilter?.id
        }
    })
    const { data: data3 } = useSubscription(apiNotUsed, {
        variables: {
            start: calculateRange(180).start,
            end: calculateRange(180).end,
            facility_id: facilityFilter?.id
        }
    })

    useEffect(() => {
        setChargerStatus([
            { heading: "Used Within 15 days", status: data?.view_charger_operations_aggregate?.aggregate?.count ?? 0, table: data?.view_charger_operations_aggregate?.nodes ?? [] },
            { heading: "Used Within 30 days", status: data1?.view_charger_operations_aggregate?.aggregate?.count ?? 0, table: data?.view_charger_operations_aggregate?.nodes ?? [] },
            { heading: "Used Within 60 days", status: data2?.view_charger_operations_aggregate?.aggregate?.count ?? 0, table: data?.view_charger_operations_aggregate?.nodes ?? [] },
            { heading: "Unused For Over 6 Months", status: data3?.view_charger_operations_aggregate?.aggregate?.count ?? 0, table: data?.view_charger_operations_aggregate?.nodes ?? [] },
        ])
    }, [data, data1, data2, data3, facilityFilter])

    const [tab, setTab] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState(null);

    const openStatusModal = (e, d) => {
        setTab(d);
        setOpenModal(true);
    }

    function formatDate(date) {
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
        let yyyy = date.getFullYear();
        let hh = String(date.getHours()).padStart(2, '0');
        let min = String(date.getMinutes()).padStart(2, '0');
        let ss = String(date.getSeconds()).padStart(2, '0');

        return `${dd}-${mm}-${yyyy} ${hh}:${min}:${ss}`;
    }


    const formateTableData = (toFormateData) => {


        const formateData = (content, type) => {
            return content?.map(item => ({
                charger_name: item?.charger_name,
                connector_id: item?.connector_id,
                facility_name: item?.facility_name,
                last_updated_at: item?.last_used ? formatDate(new Date(item?.last_used)) : ''
            }))
        }

        const headers = [
            { label: 'charger', id: 'charger_name' },
            { label: 'connector id', id: 'connector_id' },
            { label: 'facility', id: 'facility_name' },
            { label: 'last updated at', id: 'last_used' },
        ]


        setTableData({
            type: "table",
            name: null,
            tabs: toFormateData.map(item => ({
                name: item.heading,
                headers: headers,
                table_content: formateData(item.table, item.heading),
                count: item.table?.length ?? 0
            }))
        })
    }

    useEffect(() => {
        if (chargerStatus?.length > 0)
            formateTableData(chargerStatus);
    }, [chargerStatus])

    const handleExportExcel = () => {
        if (!tableData || !tableData?.tabs ) return;

        const workbook = XLSX.utils.book_new();

        tableData?.tabs?.forEach((tab) => {
            if (tab.table_content.length > 0) {
                const filteredData = tab.table_content.map(({ charger_name, connector_id, facility_name, last_used }) => ({
                    charger: charger_name,
                    "connector id": connector_id,
                    facility: facility_name,
                    "last_updated_at": last_used,
                }));

                const worksheet = XLSX.utils.json_to_sheet(filteredData);
                XLSX.utils.book_append_sheet(workbook, worksheet, tab.name);
            }
        }); 
        
        if(workbook.SheetNames.length > 0)
        XLSX.writeFile(workbook, `Charger_types.xlsx`);
    };

    return (
        <Grid item backgroundColor={boxColor} sx={{ borderRadius: '16px', marginBottom: '24px' }}>
            {chargerStatus &&
                <Box sx={{ padding: '20px' }}>
                    <Typography variant="h5" component='h5' sx={{ marginBottom: '24px', fontWeight: '600', fontSize: '20px' }}>
                        {t('chargerOperationStatus')}
                    </Typography>
                    <Grid container justifyContent='space-between'>
                        {
                            chargerStatus?.map((item, index) => <Grid item xs={2.3} sx={{
                                padding: '4px 7px',
                                justifyContent: 'space-between',
                                borderLeft: `4px solid ${colors[index]}`,
                                display: 'flex',
                                alignItems: 'center',
                                cursor: "pointer"
                            }}
                                key={item?.key}
                                onClick={(e) => openStatusModal(e, index)}
                            >
                                <Typography variant='overline' component='span' sx={{ marginRight: '15px', lineHeight: '16px', textTransform: 'capitalize', fontSize: '12px', fontWeight: '500', color: '#5C677D' }}>
                                    {item?.heading}
                                </Typography>
                                <Typography variant='subtitle2' component='span' sx={{ fontWeight: '600', fontSize: '14px', color: '#001233' }}>
                                    {item?.status}
                                </Typography>
                            </Grid>)
                        }
                    </Grid>
                </Box>
            }
            {openModal &&
                (
                    <>
                        <CustomModal open={openModal} onClose={() => setOpenModal(false)} bgColor='white' width='50vw' overflow='clip'>
                            <Grid sx={{ paddingInline: '16px' }}>
                                <CustomTabs
                                    formTabs={tableData?.tabs}
                                    tabValue={tab}
                                    handleTabChange={(e, newValue) => setTab(newValue)}
                                    showCount={true}
                                />
                            </Grid>
                            <Grid container justifyContent='flex-start' m={2}>
                                <ExportExcel key='10' onClick={handleExportExcel} />
                            </Grid>
                            <DynamicTable
                                setSelectedData={setTab}
                                tabValue={tab}
                                searchedData={tableData?.tabs[tab]?.table_content}
                                tabs={tableData?.tabs}
                                maxHeight='calc(70vh - 145px)'
                            />
                        </CustomModal>
                    </>
                )}
        </Grid>
    );
}

export default ChargerOperationStatus;