import { useSubscription } from "@apollo/client";
import TotalTransactionsIcon from "../components/dashboard/icons/TotalTransactions";
import { API_UNITS_CONSUMED_AND_POWER_TYPE_SUBSCRIPTION, API_UNITS_CONSUMED_SUBSCRIPTION, API_UNITS_CONSUMED_WITH_ALL_FACILITY_AND_POWER_TYPE_SUBSCRIPTION, API_UNITS_CONSUMED_WITH_ALL_FACILITY_SUBSCRIPTION } from "../graphql/query";
import { useState } from "react";
import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';

const TotalTransactions = ({ dateFilter, facilityFilter,powerTypeFilter }) => {

    const api = facilityFilter?.name === 'All' ? 
    powerTypeFilter === "Both" || !powerTypeFilter ?  API_UNITS_CONSUMED_WITH_ALL_FACILITY_SUBSCRIPTION: API_UNITS_CONSUMED_WITH_ALL_FACILITY_AND_POWER_TYPE_SUBSCRIPTION
     : 
     powerTypeFilter === "Both" || !powerTypeFilter ? API_UNITS_CONSUMED_SUBSCRIPTION: API_UNITS_CONSUMED_AND_POWER_TYPE_SUBSCRIPTION;
    const power_type_query = powerTypeFilter === "Both" || !powerTypeFilter ? {} : {power_type: powerTypeFilter}
    const variables = {
        variables: {
            start: dateFilter?.start?.toISOString(),
            end: dateFilter?.end?.toISOString(),
            facility_id: facilityFilter?.id,
            ...power_type_query
        }
    }

    const { data } = useSubscription(api, variables);
    const [totalTransByTime, setTotalTransByTime] = useState("0");
    const [totalTransByUnit, setTotalTransByUnit] = useState("0");
    const [totalTransByPrice, setTotalTransByPrice] = useState("0");
    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();
    const [id, setId] = useState(facilityFilter?.id)

    useEffect(() => setId(facilityFilter?.id), [facilityFilter])


    useEffect(() => {
        setTotalTransByTime(0);
        setTotalTransByUnit(0);
    }, [id, dateFilter])

    useEffect(() => {
        setLoading(true);
        if (data?.view_facility_revenue_aggregate?.aggregate?.sum) {
          let sessionByTime = (data?.view_facility_revenue_aggregate?.aggregate?.sum?.session_by_time ?? 0);
          let sessionByUnit = (data?.view_facility_revenue_aggregate?.aggregate?.sum?.session_by_unit ?? 0);
          let sessionByPrice = (data?.view_facility_revenue_aggregate?.aggregate?.sum?.session_by_price ?? 0);
          sessionByTime = sessionByTime === 0 ? '0' : sessionByTime.toFixed(0);
          sessionByUnit = sessionByUnit === 0 ? '0' : sessionByUnit.toFixed(0);
          sessionByPrice = sessionByPrice === 0 ? '0' : sessionByPrice.toFixed(0);
          setTotalTransByTime(Number(sessionByTime).toLocaleString())
          setTotalTransByUnit(Number(sessionByUnit).toLocaleString())
          setTotalTransByPrice(Number(sessionByPrice).toLocaleString())
        }
        setLoading(false)
    }, [data, facilityFilter, dateFilter]);

    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            bgcolor={"white"}
            justifyContent={"space-between"}
            borderRadius={"16px"}
            padding={"24px"}
            height='150px'
        >
            <Typography>
                <Typography variant="subtitle" fontSize='12px' fontWeight='600' style={{ textWrap: 'nowrap' }}>{t('totalTransactions')}{loading ? (<span style={{ color: '#467CEB' }}> {t('loading')}...</span>) : ''}</Typography>
                <Typography variant="h6" fontSize='18px' fontWeight='700' style={{ textWrap: 'nowrap' }}>{totalTransByTime} <span style={{ fontSize: '12px'}}>by Time</span></Typography>
                <Typography variant="h6" fontSize='18px' fontWeight='700' style={{ textWrap: 'nowrap' }}>{totalTransByUnit} <span style={{ fontSize: '12px'}}>by Unit</span></Typography>
                <Typography variant="h6" fontSize='18px' fontWeight='700' style={{ textWrap: 'nowrap' }}>{totalTransByPrice} <span style={{ fontSize: '12px'}}>by Price</span></Typography>
            </Typography>
            <TotalTransactionsIcon />
        </Box>
    );
}
export default TotalTransactions;