import { AlarmAdd } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

const timeZones = ["UTC", "Asia/Jakarta", "Asia/Kolkata", "Asia/Bangkok", "Asia/Tokyo"];

const frequencies = [
  { label: "Mon", value: "mo" },
  { label: "Tue", value: "tu" },
  { label: "Wed", value: "we" },
  { label: "Thu", value: "th" },
  { label: "Fri", value: "fr" },
  { label: "Sat", value: "sa" },
  { label: "Sun", value: "su" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function CustomJSCalendar({
  label,
  rows = 2,
  placeholder,
  col_size,
  required,
  value: propValue,
  onValueChange,
  disabled,
  errorMessage,
  isError,
}) {
  const [editorOpen, setEditorOpen] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [value, setValue] = useState(() => {
    if (!propValue || Object.keys(propValue).length === 0) {
      return {
        "@type": "Group",
        uid: crypto.randomUUID(),
        updated: new Date().toISOString(),
        name: "JS Calendar",
        entries: [],
      };
    }
    return propValue;
  });

  const handlChipAdd = () => {
    const entry = newEntry();
    handleChipClick(entry);
  };
  const handleChipClick = (entry) => {
    setSelectedEntry(entry);
    setEditorOpen(true);
  };

  const handleChipDelete = (uid) => {
    const newValue = structuredClone(value);
    newValue.entries = newValue.entries.filter((entry) => entry.uid !== uid);
    setValue(newValue);
    onValueChange(newValue);
  };

  const handleEditorSave = (entry) => {
    const newValue = structuredClone(value);
    newValue.updated = new Date().toISOString();
    if (newValue.entries.some((ent) => ent.uid === entry.uid)) {
      newValue.entries = newValue.entries.map((ent) => (ent.uid === entry.uid ? structuredClone(entry) : ent));
    } else {
      newValue.entries.push(entry);
    }
    setValue(newValue);
    onValueChange(newValue);
  };

  const handleEditorClose = () => {
    setEditorOpen(false);
    setSelectedEntry(null);
  };

  function newEntry() {
    return {
      "@type": "Event",
      uid: crypto.randomUUID(),
      updated: new Date().toISOString(),
      title: "Event Title",
      start: `${new Date().toISOString().split("T")[0]}T06:00`,
      timeZone: "UTC",
      duration: "PT1H",
      recurrenceRules: [
        {
          "@type": "RecurrenceRule",
          byDay: frequencies.map((fr) => fr.value),
        },
      ],
    };
  }

  return (
    <>
      <Grid item xs={col_size}>
        <Typography style={{ fontSize: "14px", fontWeight: "600", lineHeight: "18px" }}>
          {label}
          {required && <span style={{ color: "red", marginLeft: "5px" }}>*</span>}
        </Typography>
        <Box
          sx={{
            bgcolor: "#fafafa",
            border: "1px solid #ccc",
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            margin: "auto",
            mb: 2,
            mt: 1,
            p: 2,
            position: "relative",
            width: "100%",
          }}
        >
          <Box sx={{ alignItems: "center", display: "flex", flexWrap: "wrap", gap: 1, minHeight: "50px" }}>
            {value.entries.map((entry) => (
              <Chip
                color="primary"
                key={entry.uid}
                label={entry.title}
                onClick={() => handleChipClick(entry)}
                onDelete={() => handleChipDelete(entry.uid)}
                variant="outlined"
              />
            ))}

            <IconButton
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
              onClick={handlChipAdd}
            >
              <AlarmAdd />
            </IconButton>
          </Box>
        </Box>
        {isError ? (
          <Typography color="error" style={{ position: "relative", top: "-7px" }} variant="caption">
            {errorMessage}
          </Typography>
        ) : (
          <Typography color="error" style={{ position: "relative", top: "-7px" }} variant="caption">
            {helperText}
          </Typography>
        )}
      </Grid>
      {selectedEntry && (
        <EntryEditor entry={selectedEntry} open={editorOpen} onClose={handleEditorClose} onSave={handleEditorSave} />
      )}
    </>
  );
}

export default CustomJSCalendar;

function EntryEditor({ entry, open, onClose, onSave }) {
  const [editedEntry, setEditedEntry] = useState(entry);
  const [duration, setDuration] = useState(Number(entry?.duration.split("")?.slice(2, -1).join("")));
  const [frequency, setFrequency] = useState(entry?.recurrenceRules[0]?.byDay);
  const [startDate, setStartDate] = useState(entry?.start.split("T")[0]);
  const [startTime, setStartTime] = useState(entry?.start.split("T")[1]);
  const [timeZone, setTimeZone] = useState(entry?.timeZone);
  const [title, setTitle] = useState(entry?.title);

  useEffect(() => {
    setEditedEntry({
      ...editedEntry,
      title: title,
      updated: new Date().toISOString(),
      start: `${startDate}T${startTime}`,
      timeZone: timeZone,
      duration: `PT${duration}H`,
      recurrenceRules: [
        {
          "@type": "RecurrenceRule",
          byDay: frequency,
        },
      ],
    });
  }, [duration, frequency, startDate, startTime, timeZone, title]);

  const handleSave = () => {
    onSave(editedEntry);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Event Title"
              margin="normal"
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              variant="outlined"
              value={title}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Start Date"
              margin="normal"
              onChange={(e) => setStartDate(e.target.value)}
              sx={{ flex: { sm: "1 1 30%" } }}
              type="date"
              variant="outlined"
              value={startDate}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Start Time"
              margin="normal"
              onChange={(e) => setStartTime(e.target.value)}
              sx={{ flex: { sm: "1 1 30%" } }}
              type="time"
              variant="outlined"
              value={startTime}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Duration (Hours)"
              margin="normal"
              onChange={(e) => setDuration(e.target.value)}
              sx={{ flex: { sm: "1 1 30%" } }}
              type="number"
              variant="outlined"
              value={duration}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Time Zone"
              margin="normal"
              onChange={(e) => setTimeZone(e.target.value)}
              select
              value={timeZone}
              variant="outlined"
            >
              {timeZones.map((tz) => (
                <MenuItem key={tz} value={tz}>
                  {tz}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth style={{ marginTop: "16px" }}>
              <InputLabel id="frequency-label">Frequency</InputLabel>
              <Select
                id="frequency-select"
                label="Frequency"
                labelId="frequency-label"
                MenuProps={MenuProps}
                multiple
                onChange={(e) => setFrequency(e.target.value)}
                value={frequency}
              >
                {frequencies.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
