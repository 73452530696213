import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../apiSetup/apiCall";

export const submitEditAdminData = createAsyncThunk(
  "submitEditAdmin/fetchEditSubmitAdmin",
  async ({ stationId, userId, payload }) => {
    const facilityID = JSON.parse(window.localStorage.getItem("selectedStation") || "null");
    try {
      const response = await api.post(
        `prometheus/${facilityID?.id}/${userId}/admin/edit/`,
        payload
      );
      return response.data;
    } catch (error) {
      console.error("Error Submitting Data:", error);
      return error.response.data;
    }
  }
);

const SubmitEditAdminSlice = createSlice({
  name: "submitEditAdmin",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitEditAdminData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(submitEditAdminData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(submitEditAdminData.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default SubmitEditAdminSlice.reducer;
