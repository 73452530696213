import { createSlice } from '@reduxjs/toolkit';

const initialState = {
 list: ['AC','DC', 'Both'],
 appliedFilter: 'Both'
};

const PowerTypeFilterSlice = createSlice({
  name: 'powerTypeFilter',
  initialState,
  reducers: {
    changeFilter:(state,action)=>
    {
      state.appliedFilter=action.payload;
    }
  },
});


export const {changeFilter}=PowerTypeFilterSlice.actions;
export default PowerTypeFilterSlice.reducer;
