import {
    Grid,
    Paper,
    Typography,
    styled,
    Button,
    Divider,
    CircularProgress,
    Box,
} from "@mui/material";
import ProfileSection from "../ProfileSection";
import { useEffect, useState } from "react";
import api from "../../apiSetup/apiCall";
import NewDatePicker from "../NewDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentsAndTransactionsData } from "../../slice/PaymentsAndTransactionsSlice";
import DynamicTable from "../customComponents/DynamicTable";
import CustomTabs from "../customComponents/CustomTabs";
import * as XLSX from "xlsx";
import ExportExcel from "../buttonComponents/ExportExcel";
import SearchButton from "../buttonComponents/SearchButton";
import UnitConsumed from "../../components/dashboard/icons/UnitConsumed";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PaymentsAndTransactions = () => {

    const { selectedDate } = useSelector(state => state.datePicker);
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.paymentsAndTransactions?.isLoading);
    const paymentsAndTransactionsData = useSelector(state => state.paymentsAndTransactions?.data?.data)
    const apiTabs = paymentsAndTransactionsData && paymentsAndTransactionsData.tabs ? paymentsAndTransactionsData.tabs : [];
    const [tabValue, setTabValue] = useState(0);
    const [searchedPaymentsAndTransactionData, setSearchedPaymentsAndTransactionData] = useState([]);
    const [tableSearchText, setTableSearchText] = useState("")
    const { currency } = useSelector((state) => state.user);
    const { t } = useTranslation();
    const userData = useSelector((state) => state.user.data.data);
    const countryData = useSelector((state) => state.country.data.data);
    const navigate = useNavigate();

    if(!(countryData?.find(item => userData?.country === item?.name)?.is_refund_enabled) || !(userData?.facility_admin_type === "superadmin" || userData?.facility_admin_type === "countryadmin"))
        navigate("/dashboard")

    useEffect(() => {
        const currentTabData = paymentsAndTransactionsData?.tabs[tabValue];
        setSearchedPaymentsAndTransactionData(currentTabData ? currentTabData.table_content : []);
    }, [paymentsAndTransactionsData, tabValue])

    useEffect(() => {
        if (selectedDate) {
            const startISOString = selectedDate?.startForDetails?.toISOString()?.split('T')[0];
            const endISOString = selectedDate?.end?.toISOString()?.split('T')[0];
            dispatch(fetchPaymentsAndTransactionsData({ startDate: startISOString, endDate: endISOString }));
        }
    }, [selectedDate])

    const handleTabChange = (_event, newValue) => {
        setTabValue(newValue);
        const currentTabData = apiTabs[newValue];
        if (currentTabData && currentTabData.table_content) {
            setSearchedPaymentsAndTransactionData(currentTabData.table_content);
        }
    };

    const handleExportToExcelClick = () => {
        const ws = XLSX.utils.json_to_sheet([]);
        const wb = XLSX.utils.book_new();

        const currentTabData = paymentsAndTransactionsData.tabs[tabValue];
        let buttonTabs = [];
        if (Array.isArray(currentTabData.table_content) && currentTabData.table_content.length > 0) {
            const values = Object.entries(currentTabData.table_content[0])
            values?.forEach(item => {
                if (item[1]?.type === "button")
                    buttonTabs.push(item[0])
            })
        }

        const headers = currentTabData.headers
            .filter(
                (header) =>
                    header.id !== "id" && !buttonTabs.includes(header.id)
            )
            .map((header) => header?.label);

        // Add headers to the worksheet
        XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" });

        const tableContent = currentTabData.table_content.map((item) => {
            const rowData = currentTabData.headers
                .filter(
                    (header) =>
                        header.id !== "id" &&
                        !buttonTabs.includes(header.id)
                )
                .map((header) => {
                    const value = item[header.id];
                    if (header.id === "transaction_state")
                        return value?.status
                    if (header.id === "refund") {
                        if (value?.refund_status === "Completed")
                            return "True"
                        else
                            return "False"
                    }
                    return typeof value === "object" ? value?.label : value;
                });
            return rowData;
        });

        // Add table content to the worksheet
        XLSX.utils.sheet_add_aoa(ws, tableContent, { origin: "A2" });

        const sheetName = currentTabData.name || "Sheet1";
        XLSX.utils.book_append_sheet(wb, ws, sheetName);

        // Save Excel file
        const fileName = `payments_users.xlsx`;
        XLSX.writeFile(wb, fileName);
    };

    const handleSearchButton = (tabName, searchText) => {
        switch (tabName) {
            case "User Payment":
                handleSearch(searchText);
                break;
            default:
                break;
        }
    };

    const handleSearch = (searchText) => {
        setTableSearchText(searchText);
        if (searchText.trim() === "") {
            const currentTabData = paymentsAndTransactionsData.tabs[tabValue];
            if (currentTabData && currentTabData.table_content) {
                setSearchedPaymentsAndTransactionData(currentTabData.table_content);
            }
        } else {
            const filteredData = searchedPaymentsAndTransactionData.filter((data) => {
                const searchTextLowerCase = searchText.toLowerCase();
                return Object.values(data).some(value => {
                    if (typeof value === "string") {
                        return value.toLowerCase().includes(searchTextLowerCase);
                    } else if (typeof value === "number") {
                        return value.toString().toLowerCase().includes(searchTextLowerCase);
                    } else if (value && typeof value === "object") {
                        return Object.values(value).some(val => {
                            if (typeof val === "string") {
                                return val.toLowerCase().includes(searchTextLowerCase);
                            } else if (typeof val === "number") {
                                return val.toString().toLowerCase().includes(searchTextLowerCase);
                            }
                            return false;
                        });
                    }
                    return false;
                });
            });

            setSearchedPaymentsAndTransactionData(filteredData);
        }
    };

    const renderButtons = () => {
        const currentTabData = paymentsAndTransactionsData?.tabs[tabValue];
        console.log(currentTabData)

        if (currentTabData && currentTabData.filters_and_buttons) {
            const placeholder = `Search ${currentTabData.name || "Item"}`;
            return currentTabData.filters_and_buttons.map((button, index) => {
                switch (button) {
                    case "Search":
                        return (
                            <SearchButton
                                key={index}
                                placeholder={placeholder}
                                handleOnSearch={(searchText) =>
                                    handleSearchButton(currentTabData.name, searchText)
                                }
                                value={tableSearchText}
                            />
                        );
                    case "Export to Excel":
                        return (
                            <ExportExcel key={index} onClick={handleExportToExcelClick} />
                        );
                    default:
                        return null;
                }
            });
        }
    };

    return (
        <Grid>
            <Grid container rowSpacing={3} columnSpacing={3} alignItems='center' style={{ backgroundColor: "white", marginBottom: "20px" }}>
                <Grid item xs={8} style={{ flexGrow: 1, padding: '0 20px', alignItems: 'center', display: "flex" }}>
                    <Typography variant="h4" fontSize='20px' fontWeight='600'>
                        {t('paymentsAndTransactions')}
                    </Typography>
                </Grid>
                <Grid item xs={4} style={{ paddingTop: "5px" }}>
                    <ProfileSection />
                </Grid>
            </Grid>
            <Grid container mb={3}>
                <NewDatePicker />
            </Grid>
            <Grid container mb={3}>
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    bgcolor={"white"}
                    justifyContent={"space-between"}
                    borderRadius={"16px"}
                    padding={"24px"}
                    height='130px'
                    mr={2}
                >
                    <Typography>
                        <Typography
                            variant="subtitle"
                            fontSize='12px'
                            fontWeight='600'
                            style={{ textWrap: 'nowrap' }}
                        >
                            {t('totalAmountReceived')}
                        </Typography>
                        <Typography
                            variant="h5"
                            fontSize='24px'
                            fontWeight='700'
                            sx={{ marginTop: '10px' }}
                            style={{ textWrap: 'nowrap' }}
                        >
                            {`${currency} ${paymentsAndTransactionsData?.total_amount_received || "0"}`}
                        </Typography>
                    </Typography>
                    <UnitConsumed />
                </Box>

                <Box
                    display={"flex"}
                    alignItems={"center"}
                    bgcolor={"white"}
                    justifyContent={"space-between"}
                    borderRadius={"16px"}
                    padding={"24px"}
                    height='130px'
                >
                    <Typography>
                        <Typography
                            variant="subtitle"
                            fontSize='12px'
                            fontWeight='600'
                            style={{ textWrap: 'nowrap' }}
                        >
                            {t('totalRefundProcessed')}
                        </Typography>
                        <Typography
                            variant="h5"
                            fontSize='24px'
                            fontWeight='700'
                            sx={{ marginTop: '10px' }}
                            style={{ textWrap: 'nowrap' }}
                        >
                            {`${currency} ${paymentsAndTransactionsData?.total_refund_processed || "0"}`}
                        </Typography>
                    </Typography>
                    <UnitConsumed />
                </Box>
            </Grid>
            {isLoading ?
                <Grid sx={{ position: "absolute", top: "50%", left: "50%" }}>
                    <CircularProgress />
                </Grid>
                :
                <Grid>
                    <Grid style={{ background: "white", borderRadius: "16px" }}>
                        <Grid style={{ padding: "8px 16px 0 16px" }}>
                            <CustomTabs
                                formTabs={apiTabs}
                                tabValue={tabValue}
                                handleTabChange={handleTabChange}
                            />
                        </Grid>
                        <Grid container justifyContent="space-between" marginBlock="10px" style={{ padding: "8px 16px 0 16px" }}>
                            {renderButtons()}
                        </Grid>
                        <DynamicTable
                            tabValue={tabValue}
                            searchedData={searchedPaymentsAndTransactionData}
                            tabs={paymentsAndTransactionsData?.tabs}
                        />
                    </Grid>
                </Grid>}
        </Grid>
    );
}
export default PaymentsAndTransactions;