import Dialog from '@mui/material/Dialog';
import { Grid, Typography, Box } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const ChargerSessionGraphModal = ({ open, handleClose, data }) => {

    const { t } = useTranslation();

    const labelSet = [
        { name: t('voltage'), color: "blue" },
        { name: t('current'), color: "red" },
        { name: t('power'), color: "green" },
    ]

    return (
        <Dialog onClose={handleClose} open={open}>
            <Grid container maxWidth="700px" padding="10px">
                <Grid container sx={{ position: "relative" }}>
                    <Typography sx={{ padding: "6px 10px 20px", textAlign: "center", fontSize: "17px", fontWeight: '600', width: "100%" }}>
                        {t('chargerSessionGraph')}
                    </Typography>
                    <CloseIcon cursor="pointer" sx={{ position: "absolute", right: '20px', top: "12px", fontSize: "15px" }} onClick={handleClose} />
                </Grid>
                <Grid container sx={{ gap: "10px", marginBottom: "2px" }} justifyContent='center'>
                    {
                        labelSet.map((item, index) => <Grid item key={index}>
                            <Grid container alignItems='center' sx={{ gap: '4px' }} justifyContent='center'>
                                <Box sx={{ width: '10px', height: '10px', backgroundColor: item.color }} />
                                <Typography fontSize="12px">{item?.name}</Typography>
                            </Grid>
                        </Grid>)
                    }
                </Grid>
                <Grid container wrap="nowrap" backgroundColor="white" borderRadius='20px'>
                    <Grid item>
                        <Grid container alignItems='center' height='100%'>
                            <Typography sx={{ transform: "rotate(270deg)", "fontSize": "12px" }}>
                            {t('value')}
                            </Typography>
                        </Grid>

                    </Grid>
                    <LineChart
                        dataset={data}
                        xAxis={[{ dataKey: 'time', label: t('time'), labelStyle: { "fontSize": "12px" } }]}
                        series={[
                            { dataKey: 'voltage', color: 'blue', showMark: false, },
                            { dataKey: 'current', color: 'red', showMark: false, },
                            { dataKey: 'power_import', color: 'green', showMark: false, }
                        ]}
                        // yAxis={[{ label: "Value" }]}
                        height={230}
                        margin={{ left: 50, right: 30, top: 30, bottom: 43 }}
                        grid={{ horizontal: true }}
                    />
                </Grid>
            </Grid>
        </Dialog>
    );
}

export default ChargerSessionGraphModal;